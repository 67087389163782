import Demo from './Demo';

function App() {
  return (
    <div>
      <Demo />
    </div>
  );
}

export default App;