//import React from 'react';

const test = (process.env.REACT_APP_ENVIRONMENT !== 'production');
// console.log("process.env.NODE_ENV", process.env.NODE_ENV)
//TODO interface ?

const prod = {
    debug: test,
    APISAILS_URL: 'https://apisails.timenjoy.fr',
    WEBSITE_URL: 'https://timenjoy.fr',
    IFRAME_URL: 'https://iframe.timenjoy.fr',
    DEMO_IFRAME_URL: 'https://demo-iframe.timenjoy.fr'
};

const devt = {
    debug: test,
    APISAILS_URL: 'https://apisails-dev.timenjoy.fr',
    WEBSITE_URL: 'https://timenjoy-dev.timenjoy.fr',
    IFRAME_URL: 'https://iframe-dev.timenjoy.fr',
    DEMO_IFRAME_URL: 'https://demo-iframe-dev.timenjoy.fr'
    // IFRAME_URL: 'http://localhost:3000',
    // DEMO_IFRAME_URL: 'http://localhost:3001'
};

const local = {
    debug: test,
    APISAILS_URL: 'http://localhost:1338',
    WEBSITE_URL: 'http://localhost:4300',
    IFRAME_URL: 'http://localhost:3000',
    DEMO_IFRAME_URL: 'http://localhost:3001'
};

const config = (process.env.REACT_APP_ENVIRONMENT === 'development') ? devt : ((process.env.REACT_APP_ENVIRONMENT === 'local') ? local : prod);
export default config;
