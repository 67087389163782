import axios from "axios";
import { APISAILS_URL } from "./apiUrl";

const _authorizationHeaders = (apiToken) => {
  return ({
    Authorization: (apiToken && apiToken) ? "Bearer " + apiToken : "",
    "Content-Type": "application/json"
  })
};

const handleError = err => {
  console.error(`Api call error in services -> request.js : `, err);
};

export const get = (url, params = null, apiToken) => {
    const headers = _authorizationHeaders(apiToken)
    return  axios.get(APISAILS_URL + url, {
      headers: Object.assign({}, headers),
      params: Object.assign({}, params),
    })
    .then(res => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch(err => {
      throw err;
    })
};

export const post = (url, data = {}, apiToken) => {
  const headers = _authorizationHeaders(apiToken)
  return  axios({
      url: APISAILS_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data
    })
    .then(res => {
      return res.data.result ? res.data.result : res.data;
    })
    .catch(err => {
      throw err;
    })
};

export const put = async (
  url,
  data = {},
  apiToken
) => {
  try {
    const headers = _authorizationHeaders(apiToken)
    const res = await axios({
      url: APISAILS_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patch = async (
  url,
  data = {},
  apiToken
) => {
  try {
    const headers = _authorizationHeaders(apiToken)
    const res = await axios({
      url: APISAILS_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const ApiSails = {
  get,
  post,
  put,
  patch
};
