import React, { Component } from 'react';
import { TagsService } from '../services/TagsService';
import { Plus } from 'react-feather';
export default class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchMin: 1,
      inputMin: 3,
      foundTags: [],
    }
    this.removeTag = this.removeTag.bind(this);
    this.inputKeyUp = this.inputKeyUp.bind(this);
    this.selectTag = this.selectTag.bind(this);
  }
  
  removeTag = (i) => {
    const newTags = [ ...this.props.tags ];
    newTags.splice(i, 1);
    this.props.changeTags(newTags)
  }
  
  selectTag = (e) => {
    if (this.props.tags.find(tag => tag.toLowerCase() === e.target.id.toLowerCase())) {
      return;
    }
    const tags = [...this.props.tags, e.target.id]
    this.props.changeTags(tags)
    this.setState({foundTags: null}) // Clear the suggestions field when user select one tag
    this.inputField.value = null;
  }

  inputKeyUp = (e) => {
    const input = e.target.value;


    if(input.length >= this.state.searchMin) {
      TagsService.findSimilar(this.props.apiToken, input)
      .then(result => {
        if (this.props.tags.length === 0) {
          this.setState({foundTags: result})
        } else {
          const listTagsKeys = this.props.tags.map(x => x.id);
          let filtered = result.filter(x => listTagsKeys.indexOf(x.id) === -1);
          this.setState({foundTags: filtered})
        }
      })
      .catch (error => {
        console.log('Error while searching similarTags ->', error)
      });
    }

    if (e.key === 'Enter' && input) {
      if (this.props.tags.find(tag => tag.toLowerCase() === input.toLowerCase())) {
        return;
      }
      let newTags = [...this.props.tags, input]
      this.props.changeTags(newTags)
      this.inputField.value = null;
      // ----- Create tag if it doesn't exist in DB -----
      // TagsService.createTag(this.props.apiToken, input)
      //   .then(result => console.log(result, " was added in DB"))
    } else if (e.key === 'Backspace' && !input) {
      this.removeTag(this.props.tags.length - 1);
    }
  }
        
  render() {
    const { tags } = this.props;
    const { foundTags } = this.state;

    return (
      <div className="input-tag">
        <ul className="input-tag_tags">
          { tags.map((tag, i) => (
            <li key={tag}>
              {tag}
              <button type="button" onClick={() => {this.removeTag(i)}}>+</button>
            </li>
          ))}
          <li className="input-tag_tags_input">
            <input type="text" onKeyUp={this.inputKeyUp} ref={c => {this.inputField = c}} />
          </li>
        </ul>
          <div className="d-flex">
              { this.state.foundTags && foundTags.map((foundTag, i) => (
                <div className="btn btn-outline-dark m-1 d-flex align-items-center" key={i}>
                  <Plus size={18} className="pr-1"/>
                  <span id={foundTag.name}
                        onClick={this.selectTag}>
                          {foundTag.name}
                  </span>
                </div>
              ))}
          </div>
      </div>
    );
  }
}