import React from 'react'
import config from '../services/config/config';

export default function Iframe(props) {
  const { app_secret,
          city,
          dist,
          topevents,
          categories,
          tags,
          backgroundColor,
          color,
          buttonBackgroundColor,
          buttonColor,
          font,
          googleFont,
          white,
          url,
          viewMode } = props.params
  let filters = `&city=${city}&dist=${dist}&topevents=${topevents}}]`;
  if (white) {
    filters +=  `&white=${white}&url=${url}`
  }
  if (categories.length > 0) {
    filters +=  `&categories=[${categories}]`
  }
  if (color) {
    filters +=  `&color=${color}`
  }
  if (backgroundColor) {
    filters +=  `&backgroundColor=${backgroundColor}`
  }
  if (buttonBackgroundColor) {
    filters +=  `&buttonBackgroundColor=${buttonBackgroundColor}`
  }
  if (buttonColor) {
    filters +=  `&buttonColor=${buttonColor}`
  }
  let fonts = font
    ? `&font=${font}`
    : googleFont
    ? `&googleFont=${googleFont}`
    : "";

  let selectedTags = tags.length > 0 ? `&tags=[${tags.map(t => {
    return `"${t}"`
  })}]` : '';

  let parametred = `${config.IFRAME_URL}/${viewMode}?app_secret=${app_secret}${filters}${selectedTags}${fonts}`;
  // let parametred = `${config.IFRAME_URL}/page-view?app_secret=${app_secret}${filters}${selectedTags}${fonts}`;

  return (
    <iframe
        style={{border: 'none', minHeight: '450px'}}
        id="tj-frame"
        title="TimeNJoy"
        width={viewMode == "carousel" ? "800" : "100%"}
        height={viewMode == "carousel" ? "400" : "2048"}
        src={app_secret ? parametred : ''}
      ></iframe>
  )
}
