import * as moment from "moment";
import 'moment/locale/fr';
import * as CryptoJS from "crypto-js";
import { ApiSails } from "./config/ApisailsRequest";

class AuthService {
    static getToken = () => {
        moment.locale('fr');
        const obj = {
            appId: process.env.REACT_APP_API_KEY,
            password: process.env.REACT_APP_API_PASS,
            expiredDate: moment().add(1, 'hours').format(),
        };
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(obj), process.env.REACT_APP_ENCRYPT_SECRET).toString();

        const data = {
            appId: encodeURIComponent(encrypted),
            password: "35b7009ec0"
        }
        return ApiSails.post("/auth/login", data);
    };
};

export default AuthService;
