import { ApiSails } from "./config/ApisailsRequest";

export const handleError = err => {
    console.error(`Api call error in services -> tagsService.js : `, err);
};

export const findSimilar = (apiToken, name) => {
    return ApiSails.get('/tag/findSimilar?tag=' + name, {}, apiToken)
}

export const createTag = (apiToken, name) => {
    const data = {name : name}
    return ApiSails.post('/tag', JSON.stringify(data), apiToken)
}

export const linkTagToEvent = (apiToken, tag_id, event_id) => {
    const data = { tag_id: tag_id, event_id: event_id}
    return ApiSails.post('/eventTag', JSON.stringify(data), apiToken)
}

export const TagsService = {
    createTag,
    findSimilar,
    linkTagToEvent,
}