import React, { useState, useEffect } from "react";
import Select from 'react-select';
import AuthService from './services/AuthService';
import { CategoriesService } from './services/CategoriesService';
import Iframe from "./components/Iframe";
import Tags from './components/Tags'
import { ChromePicker } from "react-color";
import { ChevronDown, Trash2, Eye, EyeOff } from 'react-feather';
import config from './services/config/config';

export default function Demo() {
  const [apiToken, setApiToken] = useState('')
  const [categories, setCategories] = useState([])
  const [hidePassword, setHidePassword] = useState(true)
  const [urlParams, setUrlParams] = useState({
    app_secret: localStorage.getItem("app_secret") || "",
    city: "Paris",
    dist: 50,
    topevents: false,
    categories: [],
    tags: [],
    backgroundColor: "",
    color: "",
    buttonBackgroundColor: "",
    buttonColor: "",
    font: "",
    googleFont: "",
    white: false, // TODO HANDLE WHITE ACCESS THROUGH APITOKEN
    url: config.DEMO_IFRAME_URL,
    viewMode: "carousel"
  });
  async function fetchToken() {
    let res = await AuthService.getToken();
    setApiToken(res.token)
    return res.token
  }

  async function fetchCategories() {
    let res1 = await fetchToken()
    let res2 = await CategoriesService.getCategories(res1);
    return res2.rows
  }

  useEffect(() => {
    fetchCategories()
    .then(r => {
      let renamedField = r.map(item => {
        return {
          value: item.name,
          name: item.name
        }
      })
      return renamedField
    })
    .then(res => {
      setCategories([...res])
    })
  }, [])

  const handleChange = (e) => {
    setUrlParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    if (e.target.name === 'app_secret')
      localStorage.setItem("app_secret", e.target.value)
      // console.log(localStorage.getItem("app_secret"))
  };

  const handleChangeComplete = (name) => (color) => {
    let encodedColor = color.hex.replace(/#/, "%23");
    setUrlParams((prevState) => ({
      ...prevState,
      [name]: encodedColor
    }));
  };

  const handleChangeSelect = (selected) => {
    let changeOpt = [];

    selected && selected.map(s => {
      return changeOpt.push(`%22${s.value}%22`)
    })
    setUrlParams((prevState) => ({
      ...prevState,
      categories: changeOpt
    }));
  };

  const handleCheckbox = () => {
    setUrlParams((prevState) => ({
      ...prevState,
      topevents: !prevState.topevents
    }));
  };
  const handleViewChange = (changeEvent) => {
    setUrlParams((prevState) => ({
      ...prevState,
      viewMode: changeEvent.target.value
    }));
  };
  const handleWhiteMark = () => {
    setUrlParams((prevState) => ({
      ...prevState,
      white: !prevState.white
    }));
  };

  const resetParams = () => {
    setUrlParams({
      backgroundColor: "",
      color: "",
      buttonBackgroundColor: "",
      buttonColor: "",
      font: "",
      googleFont: ""
    });
  };

  const changeTags = (newTagsList) => {
    setUrlParams((prevState) => ({
      ...prevState,
      tags : newTagsList
    }))
  }

  const showPassword = () => {
    setHidePassword(prevState => !prevState)
  }

  return (
    <div className="container p-3">
      <div className="row justify-content-md-center m-4">
        <div className="custom-card">
          <div className="card card-body">
            <div className="row justify-content-center">
              <div className="d-flex flex-row align-items-baseline pt-2">
                <label htmlFor="city" className="pr-2">Voir les événements aux alentours de </label>
                <input
                  name="city"
                  type="text"
                  className="form-control form-control-sm custom-input"
                  placeholder="City"
                  value={urlParams.city}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex flex-row align-items-baseline pt-2">
                <label htmlFor="dist" className="pl-2">dans un rayon de </label>
                <input
                  name="dist"
                  type="text"
                  className="form-control form-control-sm custom-input-dist pl-2"
                  placeholder="Distance"
                  value={urlParams.dist}
                  onChange={handleChange}
                />
                <span>km</span>
              </div>
            </div>

            <div className="row d-flex flex-row align-items-baseline m-3">
            {/* TopEvents section */}
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={urlParams.topevents}
                    checked={urlParams.topevents}
                    id="topEvents"
                    onClick={handleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="topEvents">
                    Ne voir que les Incontournables !
                  </label>
                </div>
              </div>
            {/* Categories & Tags section */}
              <div className="col">
                  <Select
                    id="categories"
                    title="Catégories"
                    isMulti
                    onChange={handleChangeSelect}
                    options = {categories.map((cat, idx) => {
                        return {value: cat.value, label: cat.name}
                    })}
                  >
                  </Select>
              </div>
            </div>
            <div className="row d-flex flex-row align-items-baseline m-3">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="carousel"
                  onChange={handleViewChange} checked={urlParams.viewMode === 'carousel'} />
                <label class="form-check-label" for="inlineRadio1">carousel</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="page-view"
                  onChange={handleViewChange} checked={urlParams.viewMode === 'page-view'} />
                <label class="form-check-label" for="inlineRadio2">page view</label>
              </div>
              { config.debug ?
              <div className="col offset-md-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={urlParams.white}
                    checked={urlParams.white}
                    id="whiteMark"
                    onClick={handleWhiteMark}
                  />
                  <label className="form-check-label" htmlFor="whiteMark">
                    Marque Blanche
                  </label>
                </div>
              </div> : <div></div>
            }
            </div>
            <div className="m-2">
              <label htmlFor="Tags">Tags :</label>
              <Tags apiToken={apiToken} tags={urlParams.tags} changeTags={changeTags}/>
            </div>

          {/* APP SECRET */}
          <div>
              <div>
                <div className="d-flex justify-content-between p-2" type="button" data-toggle="collapse" data-target="#collapseAppSecret" aria-expanded="false" aria-controls="collapseAppSecret">
                  <div className="pl-3">Ajouter un code app_secret <span style={{color: 'red', fontSize: '1.2rem',fontWeight: 'bold'}}>*</span></div><div className="pr-3"><ChevronDown /></div>
                </div>
              </div>
              <div className="collapse" id="collapseAppSecret">
                  <div className="d-flex justify-content-center">
                    <label className="password">
                      <input name="app_secret" defaultValue={localStorage.getItem('app_secret')} type={hidePassword ? "password" : "text"} className="password__input" onChange={handleChange}/>
                      <span className="password__show" onClick={showPassword}>{hidePassword ? <Eye /> : <EyeOff />}</span>
                    </label>
                  </div>
              </div>
            </div>

          {/* CUSTOM STYLE */}
            <div>
              <div>
                <div className="d-flex justify-content-between p-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                  <div className="pl-3">Personnaliser le style</div><div className="pr-3"><ChevronDown /></div>
                </div>
              </div>
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  {/* Fonts section */}
                  <div className="row justify-content-center">
                    <div className="d-md-flex flex-row justify-content-center align-items-baseline">
                      <label htmlFor="font" className="p-2">Custom Font :</label>
                      <div className="d-flex flex-column p-2">
                        <input
                          name="font"
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Classic Font"
                          value={urlParams.font}
                          onChange={handleChange}
                        />
                      </div>
                      <span> or </span>
                      <div className="d-flex flex-column p-2">
                        <input
                          name="googleFont"
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Google Font"
                          value={urlParams.googleFont}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Custom style section */}
                  <div className="row justify-content-center">
                    <div>
                      <a
                        className="btn btn-outline-info btn-sm btn-color p-1 m-1"
                        style={{ width: "225px" }}
                        data-toggle="collapse"
                        href="#fontColor"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Font Color
                      </a>
                      <div
                        className="collapse m-1"
                        id="fontColor"
                        style={{ position: "absolute", zIndex: 2 }}
                      >
                        <ChromePicker
                          color={urlParams.color.replace(/%23/, "#")}
                          disableAlpha
                          onChangeComplete={handleChangeComplete("color")}
                        />
                      </div>
                    </div>
                    <div>
                      <a
                        className="btn btn-outline-info btn-sm btn-color p-1 m-1"
                        style={{ width: "225px" }}
                        data-toggle="collapse"
                        href="#backColor"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Background Color
                      </a>
                      <div
                        className="collapse m-1"
                        id="backColor"
                        style={{ position: "absolute", zIndex: 2 }}
                      >
                        <ChromePicker
                          color={urlParams.backgroundColor.replace(/%23/, "#")}
                          disableAlpha
                          onChangeComplete={handleChangeComplete("backgroundColor")}
                        />
                      </div>
                    </div>
                    <div>
                      <a
                        className="btn btn-outline-info btn-sm btn-color p-1 m-1"
                        style={{ width: "225px" }}
                        data-toggle="collapse"
                        href="#btnColor"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Button Color
                      </a>
                      <div
                        className="collapse m-1"
                        id="btnColor"
                        style={{ position: "absolute", zIndex: 2 }}
                      >
                        <ChromePicker
                          color={urlParams.buttonColor.replace(/%23/, "#")}
                          disableAlpha
                          onChangeComplete={handleChangeComplete("buttonColor")}
                        />
                      </div>
                    </div>
                    <div>
                      <a
                        className="btn btn-outline-info btn-sm btn-color p-1 m-1"
                        style={{ width: "225px" }}
                        data-toggle="collapse"
                        href="#btnBackColor"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Button Background
                      </a>
                      <div
                        className="collapse m-1"
                        id="btnBackColor"
                        style={{ position: "absolute", zIndex: 2 }}
                      >
                        <ChromePicker
                          color={urlParams.buttonBackgroundColor.replace(/%23/, "#")}
                          disableAlpha
                          onChangeComplete={handleChangeComplete("buttonBackgroundColor")}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm p-1 m-1"
                      style={{maxWidth: '30px'}}
                      onClick={() => {if (window.confirm('Supprimez les modifications de couleur ?')) resetParams()}}
                    >
                      <Trash2 size={20}/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-md-center">
        <div className="col d-flex justify-content-center">
          <Iframe params={urlParams} />
        </div>
      </div>
    </div>
  );
}
